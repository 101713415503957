import React, { Component } from 'react';
import styled from 'styled-components';

import { Title, Line } from '../components/styled';
import { SupplierCard, JoinUs, SearchBar, SegmentList } from '../components';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

const SearchResult = styled.div`
  width: 100%;
  background-color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed}px;
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }
  padding: 2% 5%;
  color: ${theme.colors.white};
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const YellowText = styled.span`
  color: ${theme.colors.primary};
  font-weight: bold;
  margin-right: 0.5em;
`;

const Text = styled.span`
  margin-top: 2px;
`;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledSearchbar = styled(SearchBar)`
  background-color: ${theme.colors.text_heading};
  width: 100%;
`;

const UlList = styled.ul`
  width: ${props => (props.notFull ? '70%' : '100%')};
  list-style: none;
  @media (max-width: 789px) {
    width: ${props => (props.notFull ? '100%' : '100%')};
  }
`;

class SuppliersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fornecedores: [],
      params: {
        r: null,
        s: null,
      },
      segmentList: [],
      loading: false,
    };

    this.fetchSegments = () => {
      Axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Empresa/segmentosCertificados`
      ).then(response => {
        console.log(response);
        const newSegmentList = [];
        if (response.data.length > 0) {
          response.data.forEach((item, index) => {
            newSegmentList.push({
              id: index + 1,
              title: item.segmento,
              codSegmento: item.codSegmento,
              icon: item.linkIcone,
            });
          });
        }
        this.setState({
          segmentList: newSegmentList,
        });
      });
    };

    this.getData = async () => {
      this.setState({ loading: true });
      const { params } = this.state;
      const response = await Axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL}Empresa/certificadas`,
        {
          params: params,
        }
      );
      this.setState({
        fornecedores: response.data,
        loading: false,
      });
    };

    this.getUrlVars = () => {
      let vars = {};
      if (typeof window !== 'undefined') {
        let url = decodeURIComponent(window.location.href);
        const parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
          m,
          key,
          value
        ) {
          parts;
          vars[key] = value;
        });
      }
      return vars;
    };
  }
  componentDidMount() {
    this.fetchSegments();
    this.setState({ params: this.getUrlVars() }, () => {
      this.getData();
    });
  }
  render() {
    const { params, fornecedores, segmentList, loading } = this.state;
    console.log(params);
    return (
      <Wrapper>
        <Title>NOSSOS FORNECEDORES</Title>
        <Line />
        <StyledSearchbar isHome={false} />
        <SearchResult>
          <TextContainer>
            <YellowText>Resultado da busca:</YellowText>
            <Text>
              {' '}
              {params.r ? params.r : ''} / {params.s ? params.s : ''}
            </Text>
          </TextContainer>
        </SearchResult>
        {loading && (
          <div className="text-center mt-5">
            <ClipLoader
              sizeUnit={'px'}
              size={30}
              color={'#123abc'}
              loading={loading}
            />
            <p>Carregando...</p>
          </div>
        )}
        {fornecedores.length > 1 && (
          <UlList className="row">
            {fornecedores.map((item, key) => (
              <li className="col-sm-12 col-md-6 m-0" key={key}>
                <SupplierCard
                  nomeFantasia={item.nomeFantasia}
                  segmento={item.segmento}
                  empresa={item.empresa}
                  cidade={item.cidade + '/' + item.uf}
                  cnpj={item.cnpj}
                  finalizadoEm={'Certificado concedido em ' + item.finalizadoEm}
                  outrosDados={
                    item.emailDivulgacao +
                    ' | ' +
                    item.telefoneDivulgacao +
                    ' | ' +
                    item.site
                  }
                  validade={'Certificado válido até ' + item.validade}
                  icone={item.linkIcone}
                />
              </li>
            ))}
          </UlList>
        )}

        {fornecedores.length === 1 && (
          <UlList notFull className="row">
            {fornecedores.map((item, key) => (
              <li className="col-md-12 m-0" key={key}>
                <SupplierCard
                  nomeFantasia={item.nomeFantasia}
                  segmento={item.segmento}
                  empresa={item.empresa}
                  cidade={item.cidade + '/' + item.uf}
                  cnpj={item.cnpj}
                  finalizadoEm={'Certificado concedido em ' + item.finalizadoEm}
                  outrosDados={
                    item.emailDivulgacao +
                    ' | ' +
                    item.telefoneDivulgacao +
                    ' | ' +
                    item.site
                  }
                  validade={'Certificado válido até ' + item.validade}
                  icone={item.linkIcone}
                />
              </li>
            ))}
          </UlList>
        )}
        <SegmentList title="Segmentos" items={segmentList} />

        <StyledContainer>
          <JoinUs />
        </StyledContainer>
      </Wrapper>
    );
  }
}

export default SuppliersPage;
